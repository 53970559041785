/*main */
@media screen and (min-width: 992px) {
  .cool {
    margin-left: 310px;
  }
}

@media screen and (max-width: 992px) {
  .cool {
    margin: 0 auto;
  }
}
